import { useContext, useEffect, useState } from "react";
import Button from "../../../Components/Button/Button";
import useWinSize from "../../../Hooks/useWinSize";
import { AIOptions, AIRefTypes } from "../utils";
import { DataContext } from "../../../Context/DataContext";
import { AIGenContext } from "../../../Context/AIGen/AIGenContext";
import Modal from "../../../Components/Modal/Modal";
import { AIPromptModal } from "./AIPromptModal";
import { ReactComponent as BookIcon } from "../../../SVGs/book-solid.svg";
import { ReactComponent as DeleteIcon } from "../../../SVGs/delete.svg";
import { Slider } from "../../../Components/Input/Slider";

export const ImageGen: React.FC = () => {
  const { prompt, setPrompt, setImage, rt, refImage, setRefImage } =
    useContext(AIGenContext);
  const [model, setModel] = useState<string>(
    window.localStorage.getItem("ai-image-model") ?? "flux-pro-1.1"
  );
  const [width, setWidth] = useState<number>(1024);
  const [height, setHeight] = useState<number>(768);
  const [credits, setCredits] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [promptModalOpened, setPromptModalOpened] = useState<boolean>(false);
  const { isSmall, isDesktop } = useWinSize();

  const [refType, setRefType] = useState<"style" | "ref">("style");
  const [strength, setStrength] = useState<number>(0.5);

  const { fetcher } = useContext(DataContext);

  const isFlux = model.includes("flux");
  const minSize = isFlux ? 256 : 0;
  const maxSize = isFlux ? 1440 : 10000;
  const stepSize = isFlux ? 32 : 1;

  const fixSize = () => {
    if (model.includes("flux")) {
      const nWidth = Math.min(Math.max(256, width - (width % 32)), 1440);
      const nHeight = Math.min(Math.max(256, height - (height % 32)), 1440);
      setWidth(nWidth);
      setHeight(nHeight);
    }
  };

  const checkTokens = async (model: string) => {
    const results = await fetcher("/ai/tokens", {
      method: "GET",
      params: { model: model },
    });
    if (results?.data?.credits) {
      setCredits(results.data.credits);
    } else {
      setCredits(0);
    }
  };

  const handleModelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setModel(e.target.value);
    window.localStorage.setItem("ai-image-model", e.target.value);
  };

  const handleSumbit = async () => {
    rt?.editor.disable();
    setLoading(true);
    //API call here
    // const img = await rt?.editor.render(true);
    let results;
    if (refType === "style" && refImage) {
      results = await fetcher("/ai/image-style", {
        method: "POST",
        body: {
          image: refImage,
          prompt: prompt,
          width: width,
          height: height,
          model: model,
          options: {
            fidelity: strength,
          },
        },
      });
    } else {
      results = await fetcher("/ai/image-gen", {
        method: "POST",
        body: {
          prompt: prompt,
          width: width,
          height: height,
          model: model,
          options: {
            ...(refImage ? { image: refImage, strength: strength } : {}),
          },
        },
      });
    }
    if (results?.data?.hostedImageURL) {
      setImage(results.data.hostedImageURL);
    }
    console.log(results);
    setLoading(false);
    rt?.editor.enable();
  };

  useEffect(() => {
    checkTokens(model);
  }, [model]);

  return (
    <div className="igen-settings-container">
      <div className="igen-section">
        <h4 className="m-0 mv-5">Model</h4>
        <select
          className="igen-select"
          value={model}
          onChange={handleModelChange}
        >
          {AIOptions.image.map((option) => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>
        {credits !== 0 && <div>credits: {credits}</div>}
      </div>
      <div className="igen-section">
        <div className="igen-split-header">
          <h4 className="m-0 mv-5">Prompt</h4>
          <BookIcon
            cursor={"pointer"}
            onClick={() => setPromptModalOpened(true)}
            width={15}
            fill="var(--base-text)"
            stroke={"var(--base-text)"}
          />
        </div>
        <textarea
          className="text-area-ai"
          value={prompt}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setPrompt(e.target.value)
          }
        ></textarea>
        {/* ------------- REF IMAGE STUFF ------------- */}
        {refImage && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10px",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <select
                className="igen-select"
                value={refType}
                onChange={(e) => setRefType(e.target.value as "style" | "ref")}
              >
                {AIRefTypes.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
              <DeleteIcon
                width={"16px"}
                height={"16px"}
                onClick={() => setRefImage(undefined)}
              />
            </div>
            <img
              src={refImage}
              style={{
                width: "100px",
                height: "auto",
                borderRadius: "8px",
                marginBottom: "10px",
              }}
            />

            <Slider
              step={0.05}
              min={0}
              max={1}
              label="strength"
              value={strength}
              onChange={(v) => setStrength(v)}
            />
          </>
        )}
        {/* <CollapsableTitle
          style={{ padding: "0px", marginTop: "10px" }}
          containerStyle={{ padding: "0px" }}
          titleStyle={{ fontSize: "16px" }}
          collapsableStyle={{ padding: "0px", marginTop: "10px", gap: "10px" }}
          title={"Options"}
          useSeperator={false}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          ></div>
        </CollapsableTitle> */}
      </div>
      <div className="igen-section">
        <h4 className="m-0 mv-5">Width</h4>
        <input
          className="igen-input-size"
          type="number"
          step={stepSize}
          min={minSize}
          max={maxSize}
          value={width}
          onBlur={() => fixSize()}
          onChange={(e) => setWidth(parseInt(e.target.value))}
        />
        <h4 className="m-0 mv-5">Height</h4>
        <input
          className="igen-input-size"
          type="number"
          value={height}
          step={stepSize}
          min={minSize}
          max={maxSize}
          onBlur={() => fixSize()}
          onChange={(e) => setHeight(parseInt(e.target.value))}
        />
      </div>

      <div className="igen-section">
        <Button
          onClick={handleSumbit}
          disabled={loading}
          className={`mv-5 ${isSmall ? "" : "button-new"}`}
        >
          Submit
        </Button>
      </div>
      <Modal
        opened={promptModalOpened}
        onClose={() => setPromptModalOpened(false)}
      >
        <AIPromptModal prompt={prompt} onChange={(p: string) => setPrompt(p)} />
      </Modal>
    </div>
  );
};
